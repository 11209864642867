import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react';
import * as Sentry from "@sentry/react";
import { useMsal } from '@azure/msal-react';
import {
  SilentRequest,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';

import { msGraphScopes } from 'sso';

import { getOrganisation } from './utils';

export interface User {
  id: string;
  name: string;
  email: string;
  organisationId: string;
  organisationName: string;
};

const initUser: User = {
  id: '',
  name: '',
  email: '',
  organisationId: '',
  organisationName: '',
};

interface UserContextTypes {
  user: User;
  getAccessToken: (scopes: string[]) => Promise<string>;
};

const UserContext = createContext<UserContextTypes>({
  user: initUser,
  getAccessToken() { return new Promise(() => {})},
});

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [user, setUser] = useState<User>(initUser);

  const { instance } = useMsal();

  const getSSOToken = useCallback(async (request: SilentRequest) => {
    try {
      return await instance.acquireTokenSilent(request);
    } catch (err: any) {
      if (err instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(request);
      } else {
        Sentry.captureException(err);
      }
    }
  }, [instance]);

  const getAccessToken = useCallback(async (scopes: string[]) => {
    const { accessToken } = await getSSOToken({ scopes }) || {};

    if (!accessToken) {
      throw new Error('No accessToken returned by getSSOToken');
    }

    return accessToken;
  }, [getSSOToken]);

  const setUserInfo = useCallback(async () => {
    const { account, accessToken } = await getSSOToken({ scopes: msGraphScopes }) || {};

    if (!accessToken) {
      throw new Error('No accessToken returned by getSSOToken');
    }

    const { idTokenClaims } = account || {};

    const { oid, tid, name, email } = idTokenClaims || {};

    if (!tid || !oid || !name) {
      throw new Error('Could not parse name, tenantId, or localAccountId');
    }

    const { displayName } = await getOrganisation(accessToken, tid);

    setUser({
      name,
      id: oid,
      email: email as string || '',
      organisationId: tid,
      organisationName: displayName || '',
    })
  }, [getSSOToken]);

  useEffect(() => { setUserInfo(); }, [setUserInfo]);

  return (
    <UserContext.Provider
      value={{
        user,
        getAccessToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

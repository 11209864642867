import { useCallback, useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import { Input, Label, Spinner } from '@fluentui/react-components';

import { useUser } from 'context';
import Contact from 'components/Contact';
import { LogoutButton } from 'components/SSO';
import { Button } from 'components/Interactives';
import { useRequestTrial } from 'hooks';

const MIN_WIDTH = 300;

const MAX_WIDTH = 680;

const FONT_SIZE = 16;

type State = 'ERROR' | 'LOADING' | 'READY' | 'REQUESTED';

export const Signup = () => {
  const [state, setState] = useState<State>('LOADING');

  const [isRequesting, setIsRequesting] = useState(false);

  const [numberOfMonths, setNumberOfMonths] = useState(1);

  const [numberOfLicences, setNumberOfLicences] = useState(1);

  const { user } = useUser();

  const { requestTrial } = useRequestTrial();

  const handleRequestTrial = useCallback(async () => {
    setIsRequesting(true);

    try {
      await requestTrial(numberOfMonths, numberOfLicences);
      setState('REQUESTED');
    } catch (err) {
      Sentry.captureException(err);

      setState('ERROR');
    }

    setIsRequesting(false);
  }, [numberOfMonths, numberOfLicences, requestTrial]);

  useEffect(() => {
    if (user.id) {
      setState('READY');
    }
  }, [user])

  if (state === 'LOADING') {
    return (
      <div className="h-screen bg-background flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="py-10 px-4 bg-background flex flex-col items-center gap-10">
      <div
        className="w-full flex justify-end"
        style={{ minWidth: MIN_WIDTH, maxWidth: MAX_WIDTH }}
      >
        <LogoutButton />
      </div>
      <span
        className="w-full text-2xl font-semibold"
        style={{ minWidth: MIN_WIDTH, maxWidth: MAX_WIDTH }}
      >
        Get your trial of Virtuosis...
      </span>
      <div
        className="w-full mt-20 h-52 flex justify-center items-center"
        style={{ minWidth: MIN_WIDTH, maxWidth: MAX_WIDTH }}
      >
        {
          state === 'ERROR' ? (
            <p className="text-center text-lg">
              An unexpected error occured - please refresh page to try again.<br />
              Please contact us if the issue is persisting - we are happy to help!
            </p>
          )
          : state === 'READY' ? (
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-1">
                <Label
                  style={{ fontSize: FONT_SIZE }}
                  htmlFor={"number-of-licences-input"}
                >
                  Number of licences
                </Label>
                <Input
                  id={"number-of-licences-input"}
                  type="number"
                  size="large"
                  value={String(numberOfLicences)}
                  disabled={isRequesting}
                  onChange={(_, { value }) => {
                    const valueInt = parseInt(value, 10);

                    if (valueInt > 0) {
                      setNumberOfLicences(valueInt);
                    }
                  }}
                />
              </div>
              <div className="flex flex-col gap-1">
                <Label
                  style={{ fontSize: FONT_SIZE }}
                  htmlFor={"number-of-months-input"}
                >
                  Licence duration (months)
                </Label>
                <Input
                  id={"number-of-months-input"}
                  type="number"
                  size="large"
                  value={String(numberOfMonths)}
                  disabled={isRequesting}
                  onChange={(_, { value }) => {
                    const valueInt = parseInt(value, 10);

                    if (parseInt(value, 10) > 0) {
                      setNumberOfMonths(valueInt);
                    }
                  }}
                />
              </div>
              <Button
                style={{
                  height: "40px",
                  fontSize: FONT_SIZE,
                  justifyContent: "center",
                }}
                title="Request Trial"
                isLoading={isRequesting}
                onClick={handleRequestTrial}
              />
            </div>
          )
          : state === 'REQUESTED' ? (
            <p className="text-center text-lg">
              Thank you! We'll contact you soon for your trial invitation.
            </p>
          )
          : <></>
        }
      </div>
      <Contact />
    </div>
  );
};

export default Signup;

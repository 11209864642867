/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal.js";

export const protobufPackage = "requests.v1.trial";

export interface Trial {
  ID: string;
  Name: string;
  Email: string;
  OrganisationId: string;
  OrganisationName: string;
  NumberOfMonths: number;
  NumberOfLicences: number;
}

function createBaseTrial(): Trial {
  return {
    ID: "",
    Name: "",
    Email: "",
    OrganisationId: "",
    OrganisationName: "",
    NumberOfMonths: 0,
    NumberOfLicences: 0,
  };
}

export const Trial = {
  encode(message: Trial, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.Email !== "") {
      writer.uint32(26).string(message.Email);
    }
    if (message.OrganisationId !== "") {
      writer.uint32(34).string(message.OrganisationId);
    }
    if (message.OrganisationName !== "") {
      writer.uint32(42).string(message.OrganisationName);
    }
    if (message.NumberOfMonths !== 0) {
      writer.uint32(48).int64(message.NumberOfMonths);
    }
    if (message.NumberOfLicences !== 0) {
      writer.uint32(56).int64(message.NumberOfLicences);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Trial {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTrial();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ID = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.Name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.Email = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.OrganisationId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.OrganisationName = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.NumberOfMonths = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.NumberOfLicences = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Trial {
    return {
      ID: isSet(object.ID) ? globalThis.String(object.ID) : "",
      Name: isSet(object.Name) ? globalThis.String(object.Name) : "",
      Email: isSet(object.Email) ? globalThis.String(object.Email) : "",
      OrganisationId: isSet(object.OrganisationId) ? globalThis.String(object.OrganisationId) : "",
      OrganisationName: isSet(object.OrganisationName) ? globalThis.String(object.OrganisationName) : "",
      NumberOfMonths: isSet(object.NumberOfMonths) ? globalThis.Number(object.NumberOfMonths) : 0,
      NumberOfLicences: isSet(object.NumberOfLicences) ? globalThis.Number(object.NumberOfLicences) : 0,
    };
  },

  toJSON(message: Trial): unknown {
    const obj: any = {};
    if (message.ID !== "") {
      obj.ID = message.ID;
    }
    if (message.Name !== "") {
      obj.Name = message.Name;
    }
    if (message.Email !== "") {
      obj.Email = message.Email;
    }
    if (message.OrganisationId !== "") {
      obj.OrganisationId = message.OrganisationId;
    }
    if (message.OrganisationName !== "") {
      obj.OrganisationName = message.OrganisationName;
    }
    if (message.NumberOfMonths !== 0) {
      obj.NumberOfMonths = Math.round(message.NumberOfMonths);
    }
    if (message.NumberOfLicences !== 0) {
      obj.NumberOfLicences = Math.round(message.NumberOfLicences);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Trial>, I>>(base?: I): Trial {
    return Trial.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Trial>, I>>(object: I): Trial {
    const message = createBaseTrial();
    message.ID = object.ID ?? "";
    message.Name = object.Name ?? "";
    message.Email = object.Email ?? "";
    message.OrganisationId = object.OrganisationId ?? "";
    message.OrganisationName = object.OrganisationName ?? "";
    message.NumberOfMonths = object.NumberOfMonths ?? 0;
    message.NumberOfLicences = object.NumberOfLicences ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

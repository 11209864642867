import { useCallback } from 'react';
import axios from 'axios';

import { useUser } from 'context';
import { virtuosisScopes } from 'sso';
import { Trial } from 'protobufs/requests/v1/trial';

const useRequestTrial = () => {
  const { user, getAccessToken } = useUser();

  const requestTrial = useCallback(async (numberOfMonths: number, numberOfLicences: number) => {
    const accessToken = await getAccessToken(virtuosisScopes);

    await axios({
      method: 'POST',
      url: 'https://virtuosis-client-prod.azure-api.net/requests/v1.0/trial',
      data: {
        ID: user.id,
        Name: user.name,
        Email: user.email,
        OrganisationId: user.organisationId,
        OrganisationName: user.organisationName,
        NumberOfMonths: numberOfMonths,
        NumberOfLicences: numberOfLicences,
      },
      transformRequest: [(data, _) => Trial.encode(data).finish()],
      headers: { Authorization: accessToken },
    });
  }, [user, getAccessToken]);

  return {
    requestTrial,
  };
};

export default useRequestTrial;

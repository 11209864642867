import axios from 'axios';

export const getOrganisation = async (accessToken: string, tenantId: string) => {
  const { data } = await axios({
    method: 'GET',
    url: `https://graph.microsoft.com/v1.0/organization/${tenantId}`,
    headers: { Authorization: accessToken },
  });

  return data;
};

import { IPublicClientApplication } from '@azure/msal-browser';
import {
  FluentProvider,
  teamsLightTheme,
} from '@fluentui/react-components';
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import { UserProvider } from 'context';

import Get from './Get';
import { Login } from './SSO';

const App = ({ pca }: { pca: IPublicClientApplication }) => {
  return (
    <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
        <UserProvider>
          <FluentProvider theme={teamsLightTheme}>
            <Get />
          </FluentProvider>
        </UserProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;

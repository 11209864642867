import { useEffect, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import {
  useLocation,
  matchRoutes,
  RouterProvider,
  useNavigationType,
  createBrowserRouter,
  createRoutesFromChildren,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { 
  EventType,
  EventMessage,
  AuthenticationResult,
  PublicClientApplication
} from '@azure/msal-browser';

import App, { Error } from 'components';
import { config } from 'sso';

import 'assets/css/styles.css';

Sentry.init({
  dsn: "https://c9474adb28461a5e5e6660119a2cdff0@o4506237862412288.ingest.sentry.io/4506287263776768",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.NODE_ENV, 
});

const msalInstance = new PublicClientApplication(config);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "*",
    element: <App pca={msalInstance}/>,
    errorElement: <Error />,
  },
]);

ReactDOM
  .createRoot(document.getElementById('root') as HTMLElement)
  .render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
